<template>
  <v-container fill-height>
    <v-row justify-center align-center>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="12" md="12" xl="12">
          <v-card class="rounded-b-0 rounded-t-xl mx-auto" max-width="500px">
            <v-toolbar class="blue accent-2" >
              <v-toolbar-title class="white--text">چوونەژورەوە</v-toolbar-title>
            </v-toolbar>
            <v-form ref="login">
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-text-field @focus="$event.target.select()" autocomplete="username" label="ناوی بەکارهێنەر" v-model="username" :rules="rules.email">
                    </v-text-field>
                  </v-col>
                  <v-col >
                    <v-text-field @focus="$event.target.select()" autocomplete="current-password" label="ووشەی نهێنی" @keyup.enter="submit()" v-model="password" :rules="rules.password" :type="showPassword?'text': 'password'" :append-icon="showPassword? 'mdi-eye': 'mdi-eye-off'" @click:append="showPassword = !showPassword">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-card-actions>
              <v-container>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn class="blue accent-2 white--text mx-2 my-2" @click="submit()">چوونەژوورەوە</v-btn>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  data() {
    return {
      username: '',
      password: '',
      showPassword: false,
      rules: {
        email: [
          v => v != '' || 'تکایە ناوی بەکارهێنەر داخڵ بکە'
        ],
        password: [
          v => !v || v.toString().length >=6 || 'وووشەی نهێنی پێویستە زیاتربێت لە ٦ کارەکتەر'
        ]
      }
    }
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    }),
    submit(){
      if (this.$refs.login.validate()) {
        this.signIn({username: this.username, password: this.password})
      }
    }
  }
}
</script>

<style>
  html { overflow-y: auto }
</style>